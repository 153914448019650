var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"bordered":false}},[_c('a-row',{attrs:{"gutter":50}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('a-col',{attrs:{"md":24,"lg":12}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {rules: [{message: '请输入姓名！'}]} ]),expression:"[\n            'name',\n            {rules: [{message: '请输入姓名！'}]},\n          ]"}],attrs:{"placeholder":"请输入姓名","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"所属部门"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'deptName',
            {rules: [{message: '请输入所属部门！'}]} ]),expression:"[\n            'deptName',\n            {rules: [{message: '请输入所属部门！'}]},\n          ]"}],attrs:{"placeholder":"请输入所属部门","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            {rules: [{required: true, message: '请输入电话！'}]} ]),expression:"[\n            'phone',\n            {rules: [{required: true, message: '请输入电话！'}]},\n          ]"}],attrs:{"rows":"4","placeholder":"请输入电话","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {rules: [{required: true, message: '请输入邮箱！'}]} ]),expression:"[\n            'email',\n            {rules: [{required: true, message: '请输入邮箱！'}]},\n          ]"}],attrs:{"placeholder":"请输入邮箱"}})],1),_c('h3',{staticStyle:{"font-size":"15px"}},[_vm._v("会员积分")]),_c('a-input',{attrs:{"placeholder":"请输入会员等级","disabled":true},model:{value:(_vm.credit),callback:function ($$v) {_vm.credit=$$v},expression:"credit"}}),_c('a-form-item',{staticStyle:{"margin-top":"25px"},attrs:{"label":"会员等级"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'creditLevel',
            {rules: [{message: '请输入会员等级！'}]} ]),expression:"[\n            'creditLevel',\n            {rules: [{message: '请输入会员等级！'}]},\n          ]"}],attrs:{"placeholder":"请输入会员等级","disabled":true}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")])],1)],1),_c('a-col',{attrs:{"md":24,"lg":12}},[_c('a-form-item',{attrs:{"label":"更换头像"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['image']),expression:"['image']"}],staticClass:"avatar-uploader",attrs:{"list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUpload,"customRequest":_vm.customRequest}},[(_vm.imageUrl)?_c('img',{staticStyle:{"width":"100px","height":"100px","border-radius":"50%"},attrs:{"src":_vm.imageUrl,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loadingFile ? 'loading' : 'plus'}})],1)])],1)],1),_c('a-col',{attrs:{"md":24,"lg":12}},[_c('p',[_vm._v("等级图标")]),_c('img',{attrs:{"src":_vm.creditUrl,"alt":""}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }