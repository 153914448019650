<template>
  <div class="configer-container">
    <div class="left">
      <a-menu
        v-model="activeName"
        style="width: 256px;height: 100%;"
        :selectedKeys="activeName"
        :default-open-keys="['sub1']"
        :mode="mode"
        :theme="theme"
      >
        <a-menu-item key="settings">
          <a-icon type="user" />
          基本信息
        </a-menu-item>
        <a-menu-item key="phoneSet">
          <a-icon type="setting" />
          更改手机号
        </a-menu-item>
      </a-menu>
    </div>
    <div class="right">
      <component :is="activeName[0]" />
    </div>
  </div>
</template>
<script>
import settings from "./settings";
import phoneSet from "./phoneSet";

export default {
  name: "configure",
  components: { settings,phoneSet },
  data() {
    return {
      mode: "inline",
      theme: "light",
      activeName: ["settings"],
    };
  },
};
</script>

<style lang="less" scoped>

</style>
